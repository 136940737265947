import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'

export const ChartComponent = props => {
    const { predictId } = useParams()
    const {
        data,
        colors: {
            backgroundColor = 'rgba(0, 0, 0, 0.2)',
            lineColor = '#2962FF',
            textColor = 'white',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
        symbol,
        interval
    } = props;

    const chartContainerRef = useRef();

    const [totalData, setTotalData] = useState([])
    const [candleData, setCandleData] = useState([])
    const [lineData, setLineData] = useState([])
    const [binanceData, setBinanceData] = useState([])

    useEffect(() => {
        const getBinance = async () => {
            if(!symbol.length)return

            const url = `https://api.binance.com/api/v3/klines?symbol=${props.symbol}&interval=${props.interval}`
            const response = await fetch(url, {
                method: 'GET',
            })
            let data = await response.json()
            setBinanceData(data)
        }
        getBinance()
    }, [symbol, interval])

    useEffect(() => {
        const getBinanceDuring = async () => {
            if(!symbol.length)return
            // console.log('binance', binanceData)
            if(!binanceData.length)return
            console.log('ttt')
            const url = `https://api.binance.com/api/v3/klines?symbol=${props.symbol}&interval=${props.interval}&limit=10`
            const response = await fetch(url, {
                method: 'GET',
            })
            let data = await response.json()
            data = data.filter(datas => datas[0] > binanceData[binanceData.length - 1][0])
            setBinanceData([...binanceData, ...data])
        }
        const intervalId = setInterval(async () => await getBinanceDuring(), 300000)
        return () => {
            clearInterval(intervalId)
        }
    }, [binanceData])

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                },
                width: chartContainerRef.current.clientWidth,
                height: 300,
                // localization: {
                //     timeFormatter: businessDayOrTimestamp => {
                //         return Date(businessDayOrTimestamp); //or whatever JS formatting you want here
                //     },
                // },
                grid: {
                    vertLines: { color: '#ffffff00' },
                    horzLines: { color: '#ffffff00' },
                },
            });
            console.log('props', props)
            chart.timeScale().applyOptions({
                barSpacing: 10,
                timeVisible: true,
                secondsVisible: false
            });
            let line_data = []
            lineData.map(line => line_data.push({ time: Date.parse(line.timestamp) / 1000, value: (line.high + line.low) / 2 }))
            const lineSeries = chart.addLineSeries({ color: '#2277ff' });
            lineSeries.setData(line_data);

            let candle_data = []
            let data = binanceData
            data.map(binance => candle_data.push({time: binance[0] / 1000, 
                open: parseFloat(binance[1]), high: parseFloat(binance[2]), 
                low: parseFloat(binance[3]), close: parseFloat(binance[4])}))
            const candleSeries = chart.addCandlestickSeries()

            candleSeries.setData(candle_data)
            // const intervalId = setInterval(async () => {
            // }, 1000)

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                // clearInterval(intervalId)
                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor, lineData, binanceData]
    );

    useEffect(() => {
        const getData = async () => {
            console.log('predictId', predictId)
            const url = `https://hemeratrading.net/predict/${predictId !== undefined ? predictId : 'default'}.json`;
            console.log('url', url)
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Access-Control-Allow-Origin' : '*'
                // }
            })
            const data = await response.json()
            setLineData(data);
        }
        getData()
    }, [])

    return (
        <div className='w-full h-[100%]'
            ref={chartContainerRef}
        />
    );
};

export default ChartComponent;