/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useQueryParam, StringParam } from 'use-query-params'
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import "../styles/Dashboard.css";
import { useWeb3Modal } from "@web3modal/react";
import { readContract } from '@wagmi/core'
import Web3 from 'web3'
import ChartComponent from '../components/ChartComponent.jsx';
// import { decrypt } from '../components/decrypt.js';
import Chart from '../components/tradingview/index.js';
import { useParams } from 'react-router-dom'

const PASSWORD1 = "3ffd9193ab336c63ee4f2f42a76fd3093fc741d438ccdc2a482e0f30078229ca794ef0f2911ef0b237f1da9f61047904";
const iv1 = "LLOCVELELUYUIIUY";
const key1 = "olzC(MNBVq@IkIlp(Qwert_TGB*BvniO";
const App = () => {
  const { predictId } = useParams()
  let [username] = useQueryParam('username', StringParam)
  let [tg_username] = useQueryParam('tg_username', StringParam)
  if(username === undefined){
    username = "null"
  }
  if(tg_username === undefined){
    tg_username = "null"
  }
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const TokenAddress = "0xD0054b65B683Dbdd324B51F5F1F16AadEB99a74B";
  const apiUrl = "https://hemera-mysql-api-5527d8dc2139.herokuapp.com"
  const ETH_PROVIDER_URL = 'https://ethereum-rpc.publicnode.com'
  const ETHWeb3 = new Web3(new Web3.providers.HttpProvider(ETH_PROVIDER_URL))
  const [ethBalance, setETHBalance] = useState(0)
  const [hemBalance, setHemBalance] = useState(0)
  const { switchNetwork } = useSwitchNetwork()
  const [updated, setUpdated] = useState(false)

  const [firstConnect, setFirstConnect] = useState(false);
  const [symbol, setSymbol] = useState('')
  const [timeframe, setTimeFrame] = useState('')
  const [strategyName, setStrategyname] = useState('')
  const [strategyExplanation, setStrategyExplanation] = useState('')
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(1)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain.id !== 1)
        switchChain();
    }
  }, [isConnected, chain?.id, switchNetwork])
  const onConnectWallet = async () => {
    await open();
    setFirstConnect(true);
  };
  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload();
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true)
      reloadWindow();
  }, [isConnected, firstConnect])

  useEffect(() => {
    const FetchStakingData = async () => {
      try {
        let accountBalance = await ETHWeb3.eth.getBalance(address)
        let ethbalance = accountBalance
        let hembalance = 0;
        let tokenBalance
        tokenBalance = await readContract({
          address: TokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address]
        })
        if (Number(tokenBalance) > 0) {
          hembalance = Number(tokenBalance)
        }
        setETHBalance(ETHWeb3.utils.fromWei(accountBalance, 'ether'))
        setHemBalance(ETHWeb3.utils.fromWei(tokenBalance.toString(), 'ether'))
        await fetch(
          apiUrl + `/api/getProfile/${username}/${tg_username}/${address}/${ethbalance}/${hembalance}`,
          {
            method: 'GET'
          }
        ).then(async res => {
          
        })
        setUpdated(true)
      } catch (e) {
        console.error(e)
      }
    }

    const FetchBalance = async () => {
      try {
        let accountBalance = await ETHWeb3.eth.getBalance(address)
        let tokenBalance
        tokenBalance = await readContract({
          address: TokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address]
        })
        setETHBalance(ETHWeb3.utils.fromWei(accountBalance, 'ether'))
        setHemBalance(ETHWeb3.utils.fromWei(tokenBalance.toString(), 'ether'))
        setUpdated(true)
      } catch (e) {
        console.error(e)
      }
    }

    if (isConnected === true && chain?.id === 1 && address && (username !== "null" || tg_username !== "null") && updated === false) {
      FetchStakingData();
    }else{
      FetchBalance();
    }
  }, [isConnected, address, chain])

  useEffect(() => {
    const getData = async () => {
      console.log("222")
      console.log('predictId', predictId)
      const url = `https://hemeratrading.net/predict/${predictId !== undefined ? predictId : 'default'}.txt`;
      console.log('url', url)
      const response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
      })
      console.log('response', response)
      let data = await response.json()
      console.log('data', data)
      setSymbol(data['Symbol'])
      setTimeFrame(data['Timeframe'])
      setStrategyname(data['Strategy Name'])
      setStrategyExplanation(data['Strategy Explanation'])
    }
    getData()
  }, [])

  return (
    <main>
      <div className="GlobalContainer">
        {address ?
          chain?.id === 1 ?
            <div className="MainDashboard">
              <section className="ContactBox">
                <>
                  <section className="ContractContainer">
                    <section className="DepositBoxHeader">
                      <p className="ContractContentTextTitle">Dashboard</p>
                    </section>
                    <div className='StakingContents'>
                      <div className='PlanBox'>
                        <div className='StakingBoxs'>
                          <div className='StakingBox1'>
                            <div className='LpBalance UserBalance'>
                              <p className='HeaderText'>Your HEM Balance : </p>
                              <p className='Text1'>&nbsp; {Number(hemBalance) > 0 ? Number(hemBalance)?.toFixed(3) : 0} Hemera</p>
                            </div>
                            <div className='LpBalance UserBalance'>
                              {/* {console.log('decrypt', decrypt(PASSWORD1, key1, iv1))} */}
                              <p className='HeaderText'>Your ETH Balance : </p>
                              <p className='Text1'>&nbsp; {Number(ethBalance) > 0 ? Number(ethBalance)?.toFixed(3) : 0} ETH</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
                <div>
                  <h1>{symbol} / <span>  {timeframe}</span></h1>
                  <h3>{strategyName}</h3>
                  <h5>{strategyExplanation}</h5>
                  
                </div>
                <ChartComponent symbol={symbol} interval={timeframe}/>
                {/* <Chart symbol="BTC" interval="1h"/> */}
              </section>
            </div>
            :
            <section className="ConnectWalletBox">
              <p className="FirstNote">Please change chain</p>
              <div className="ConnectWalletBoxButton">
              </div>
            </section>
          :
          <section className="ConnectWalletBox">
            <p className="FirstNote">Please connect wallet first</p>
            <div className="ConnectWalletBoxButton">
              <button className="ConnectButton" type="submit" onClick={() => {
                onConnectWallet();
              }}>Enter App / Connect</button>
            </div>
          </section>
        }

      </div>
    </main >
  )
}

export default App
