/* eslint-disable no-unused-vars */
// import socketIO from "socket.io-client";

var latestData;
const resValues = {
  // minutes
  // 1: 1,
  // 3: 3,
  // 5: 5,
  // 15: 10,
  // 30: 30,
  // hours
  60: 60,
  120: 120,
  240: 240,
  360: 360,
  720: 720,
  // days
  "1D": 1440,
  "3D": 4320,
  "1W": 10080,
  "1M": 43200
};

function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }

  return {
    symbol: match[1],
    exchange: match[2],
  };
}

export const configurationData = {
  supports_marks: true,
  supported_resolutions: [
    // minutes
    // "1",
    // "3",
    // "5",
    // "15",
    // "30",
    // hours
    "60",
    "120",
    "240",
    "360",
    "720",
    // days
    "D",
    "W",
    "M",
  ],
  supports_search: true,
  supports_group_request: false,
  supports_timescale_marks: false,
  supports_time: true,  
};

const channelToSubscription = new Map();

// Chart Methods
// eslint-disable-next-line import/no-anonymous-default-export
const datafeed = (tokenId) => {
  return {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData), 500);
      
    },
    searchSymbols: async () => {
      // Code here...
    },
    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      let symbolInfo = {
        name: symbolName,
        has_intraday: true,
        has_no_volume: false,
        session: "24x7",
        timezone: "Europe/Athens",
        exchange: "",
        minmov: 0.00000000001,
        pricescale: 100000000,
        has_weekly_and_monthly: true,
        has_intraday: true,
        volume_precision: 2,
        data_status: "streaming",
        supported_resolutions: configurationData.supported_resolutions,
      };

      onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback
    ) => {
        const resVal = resValues[resolution];
        const { from, to } = periodParams;
        try {
            const url = 'https://hemeratrading.net/predict/default.json';
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Access-Control-Allow-Origin' : '*'
                // }
            })
            let data = await response.json()
            // setTotalData(data);
            // setCandleData(candle_data)
            // setLineData(line_data)
            console.log('data', data)
            let candleData = []
            data.map(candle => {
              // new Date(candle.timestamp).getTime() < Date.now() ? 
              // candleData.push({ time: new Date(candle.timestamp).getTime() - 86400000 * 8, open: candle.open, high: candle.high, low: candle.low, close: candle.close }) :
              candleData.push({ time: new Date(candle.timestamp).getTime() - 86400000 * 3, open: candle.open, high: candle.high, low: candle.low, close: candle.close })
            })

          if (!data.length) {
            onHistoryCallback([], { noData: true });
          }
          console.log('CcandleData', candleData)
            candleData = candleData.filter((bar) => bar.time >= from * 1000 && bar.time < to * 1000 );

            candleData = candleData.sort(function (a, b) {
            if (a.time < b.time) return -1;
            else if (a.time > b.time) return 1;
            return 0;
          });

          latestData = candleData[candleData.length - 1];
          window.delta = 0;
          console.log("data", candleData, from, to)

          onHistoryCallback(candleData, { noData: false });
        } catch (error) {
          onErrorCallback(error);
        }
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
      lastDailyBar
    ) => {
      const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
      const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.symbol}`;
      const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
      };
      let subscriptionItem = channelToSubscription.get(channelString);
      if (subscriptionItem) {
        // Already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
        return;
      }
      subscriptionItem = {
        subscribeUID,
        resolution,
        lastDailyBar: lastDailyBar || latestData,
        handlers: [handler],
      };
      channelToSubscription.set(channelString, subscriptionItem);
      console.log(
        "[subscribeBars]: Subscribe to streaming. Channel:",
        channelString
      );
      // socket.emit('SubAdd', { subs: channelString });
    },

    unsubscribeBars: (subscriberUID) => {
      // Code here...
    },
  };
};

export default datafeed;
